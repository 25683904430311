import { Box, IconButton, MenuItem, MenuList, Stack, Tooltip, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { IconStar } from 'kognia-ui/icons/IconStar';
import { IconStarFilled } from 'kognia-ui/icons/IconStarFilled';
import { MouseEvent, UIEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { usePlayerInfinityQuery } from 'entities/dashboard/api/use-player-infinity-query/usePlayerInfinityQuery';
import { useUpdatePlayerFavorites } from 'entities/dashboard/api/useUpdatePlayerFavorites';
import { routes } from 'kognia/router/routes';
import { Player } from 'shared/types/player/player';

const CONTAINER_SCROLL_PADDING = 8;
const LIST_MIN_WIDTH = 250;

const getTeamFromPlayer = (player: Player) => {
  return player.fixtures[0].team;
};

export const PlayerFavoritesList = () => {
  const { items, isLoading, fetchNextPage } = usePlayerInfinityQuery({ initialFilters: { isFavorite: true } });
  const { updatePlayerFavorites } = useUpdatePlayerFavorites();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleMenuItemClick = useCallback(
    (playerId: string) => {
      navigate(generatePath(routes.PLAYER_PROFILE, { playerId }));
    },
    [navigate],
  );

  const handleFavoritesButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, player: Player) => {
      event.stopPropagation();
      updatePlayerFavorites({
        playerId: player.id,
        isFavorite: !player.isFavorite,
      });
    },
    [updatePlayerFavorites],
  );

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.currentTarget;
      const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_SCROLL_PADDING >= target.scrollHeight;

      if (isScrollBottom && !isLoading) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isLoading],
  );

  const itemsList = items.map((item) => {
    const playerTeam = getTeamFromPlayer(item);

    return (
      <MenuItem
        dense
        key={item.id}
        sx={{ borderBottom: `1px solid ${Colors.background}`, overflowY: 'auto' }}
        onClick={() => handleMenuItemClick(item.id)}
        tabIndex={0}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          display={'grid'}
          width={'100%'}
          gridTemplateColumns={'1fr 1fr auto'}
          gap={2}
        >
          <Stack direction={'row'} alignItems={'center'} gap={1} overflow={'hidden'}>
            <PlayerAvatar withBorder size={'xsmall'} src={item.photoUrl} />
            <Typography fontSize={fontSizes.default} textOverflow={'ellipsis'} overflow={'hidden'}>
              {item.name}
            </Typography>
          </Stack>
          <Stack alignItems={'center'} direction={'row'} gap={1} overflow={'hidden'}>
            <TeamAvatar src={playerTeam.logoUrl} size={'xsmall'} />
            <Typography fontSize={fontSizes.small} textOverflow={'ellipsis'} overflow={'hidden'}>
              {playerTeam.name}
            </Typography>
          </Stack>
          <Stack>
            <Tooltip title={t(item.isFavorite ? 'favorites.remove' : 'favorites.add')}>
              <IconButton size={'small'} onClick={(event) => handleFavoritesButtonClick(event, item)}>
                {item.isFavorite ? <IconStarFilled size={'small'} color={'primary'} /> : <IconStar size={'small'} />}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </MenuItem>
    );
  });

  if (!itemsList.length) {
    return (
      <Typography fontSize={fontSizes.small} fontWeight={'fontWeightMedium'} sx={{ pt: 1, pb: 1, pl: 2, mt: 1 }}>
        {t('no-results')}
      </Typography>
    );
  }

  return (
    <Box maxHeight={420} overflow={'auto'} onScroll={handleScroll}>
      <MenuList sx={{ minWidth: LIST_MIN_WIDTH }}>{itemsList}</MenuList>
    </Box>
  );
};
