import { Box } from '@mui/material';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconPlaylist } from 'kognia-ui/icons/IconPlaylist';
import { IconTrim } from 'kognia-ui/icons/IconTrim';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import { TaggingEvent } from 'api/tagging-tool/types';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import {
  PlaylistMenuHorizontalPosition,
  PlaylistMenuVerticalPosition,
  SelectPlaylistDialog,
} from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { Playlist } from 'shared/types';
import { fetchDeleteTaggingEvent } from 'tagging-tool/service/taggingEvent.service';

export type ClipContextualMenuProps = {
  initialShow: boolean;
  hasVideo: boolean;
  onClose: () => void;
  event: TaggingEvent;
  onDelete: (event: TaggingEvent) => void;
  onTrim: (event: TaggingEvent) => void;
  onRename: (event: TaggingEvent) => void;
};

export const TagEventClipMenu = ({
  initialShow,
  hasVideo,
  onClose,
  event,
  onDelete,
  onTrim,
  onRename,
}: ClipContextualMenuProps) => {
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const { search } = useLocation();
  const { t } = useTranslation();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const [show, setShow] = useState<boolean>(initialShow);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const currentPlaylistItem = useCurrentPlaylistItem();
  useEffect(() => {
    if (show) {
      // reset UI state
      setShowDeleteConfirm(false);
    }
    setShow(show);
  }, [show]);

  const handleShowDeleteConfirm = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDelete = useCallback(() => {
    const { recordingId, id: taggingEventId } = event;

    fetchDeleteTaggingEvent({
      recordingId: recordingId,
      taggingEventsIds: [taggingEventId],
    }).then((res) => {
      if (res.error) {
        return;
      }

      onDelete(event);
    });
  }, [onDelete, event]);

  const handleRename = useCallback(() => {
    if (!onRename) return;
    onRename(event);
  }, [event, onRename]);

  const handleShowPlaylistModal = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClosePlaylistModal = useCallback(() => {
    setAnchorEl(null);
    onClose();
  }, [onClose]);

  const handleShowTrimModal = useCallback(() => {
    onClose();
    onTrim(event);
  }, [onTrim, onClose, event]);

  const handleAddClipToPlaylist = useCallback(
    (playlist: Playlist | null, name: string) => {
      if (!playlist) return;

      const { name: eventName, timeBefore, time, timeAfter, recordingId } = event;

      const data = {
        startTime: Math.max(0, time - timeBefore),
        endTime: time + timeAfter,
        playlistId: playlist.id,
        name: name ? name : eventName,
        recordingId: recordingId,
        fundamentalsSelected: currentPlaylistItem.fundamentalsSelected,
      };

      addManyToPlaylist({
        items: [data],
        options: {
          onSettled: handleClosePlaylistModal,
          onSuccess: () => {
            invalidatePlaylistsQuery();
          },
        },
      });
    },
    [addManyToPlaylist, handleClosePlaylistModal, event, currentPlaylistItem.fundamentalsSelected],
  );

  const menuOptions: MenuListOption[] = useMemo(
    () => [
      {
        displayText: t('common:actions.rename'),
        icon: <IconEdit size='small' color='secondary' />,
        onClick: handleRename,
      },
      {
        isHidden: !hasVideo,
        displayText: t('tagging-tool:tagging-recording.trim'),
        icon: <IconTrim size='small' color='secondary' />,
        onClick: handleShowTrimModal,
      },
      {
        displayText: t('tagging-tool:tagging-recording.send-to-playlist'),
        icon: <IconPlaylist size='small' color='secondary' />,
        onClick: handleShowPlaylistModal,
        avoidCloseOnClick: true,
        isHidden: search.includes('isLive'),
      },
      {
        displayText: t('common:actions.delete'),
        icon: <IconDelete size='small' color='secondary' />,
        onClick: handleShowDeleteConfirm,
      },
    ],
    [handleRename, handleShowDeleteConfirm, handleShowPlaylistModal, handleShowTrimModal, hasVideo, search, t],
  );

  return (
    <Box paddingX={1}>
      <KebabMenu ref={kebabRef} options={menuOptions} id={'tagging-tool-clip'} />
      <ConfirmPopoverDialog
        anchorEl={kebabRef.current}
        cancelLabel={t('common:actions.cancel')}
        confirmLabel={t('common:actions.delete')}
        description={t('tagging-tool:tagging-recording.delete-tag-confirm')}
        isOpen={showDeleteConfirm}
        onConfirm={handleDelete}
        setIsOpen={setShowDeleteConfirm}
      />
      <SelectPlaylistDialog
        anchorEl={anchorEl}
        onClose={handleClosePlaylistModal}
        verticalPosition={PlaylistMenuVerticalPosition.Top}
        horizontalPosition={PlaylistMenuHorizontalPosition.Center}
        onClickItem={handleAddClipToPlaylist}
      />
    </Box>
  );
};
