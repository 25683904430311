import { FormControl, FormHelperText, Input, TextField } from '@mui/material';
import { UseFormRegister, FieldErrors } from 'react-hook-form';

import {
  CompetitionFormFieldsNames,
  CompetitionFormSchema,
} from 'pages/backoffice/pages/competitions/components/competition-form/types';
import { FormFormLabel } from 'shared/components/form/form-form-label';

interface Props {
  register: UseFormRegister<CompetitionFormSchema>;
  errors: FieldErrors<CompetitionFormSchema>;
}

export function CompetitionsForm({ register, errors }: Props) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Competition Name</FormFormLabel>
        <TextField
          size='small'
          {...register(CompetitionFormFieldsNames.name)}
          error={!!errors[CompetitionFormFieldsNames.name]}
          helperText={errors[CompetitionFormFieldsNames.name] && <>{errors[CompetitionFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Country</FormFormLabel>
        <TextField
          size='small'
          {...register(CompetitionFormFieldsNames.country)}
          error={!!errors[CompetitionFormFieldsNames.country]}
          helperText={
            errors[CompetitionFormFieldsNames.country] && <>{errors[CompetitionFormFieldsNames.country].message}</>
          }
        />
      </FormControl>

      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Image URL</FormFormLabel>
        <Input
          type='file'
          size='small'
          {...register(CompetitionFormFieldsNames.competitionImage)}
          error={!!errors[CompetitionFormFieldsNames.competitionImage]}
        />
        {errors[CompetitionFormFieldsNames.competitionImage] && (
          <FormHelperText error>
            <>{errors[CompetitionFormFieldsNames.competitionImage].message}</>
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}
