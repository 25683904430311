import { Box, IconButton, MenuItem, MenuList, Stack, Tooltip, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { IconStar } from 'kognia-ui/icons/IconStar';
import { IconStarFilled } from 'kognia-ui/icons/IconStarFilled';
import { MouseEvent, UIEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useTeamsInfinityQuery } from 'entities/dashboard/api/use-teams-infinity-query/useTeamsInfinityQuery';
import { useUpdateTeamFavorites } from 'entities/dashboard/api/useUpdateTeamFavorites';
import { Country } from 'features/dashboard/team-profile-header-feature/ui/Country';
import { routes } from 'kognia/router/routes';
import { Team } from 'shared/types/team/team';

const CONTAINER_SCROLL_PADDING = 8;
const LIST_MIN_WIDTH = 250;

export const TeamFavoritesList = () => {
  const { items, isLoading, fetchNextPage } = useTeamsInfinityQuery({ initialFilters: { isFavorite: true } });
  const { updateTeamFavorites } = useUpdateTeamFavorites();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleMenuItemClick = useCallback(
    (teamId: string) => {
      navigate(generatePath(routes.TEAM_PROFILE, { teamId }));
    },
    [navigate],
  );

  const handleFavoritesButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, team: Team) => {
      event.stopPropagation();
      updateTeamFavorites({
        teamId: team.id,
        isFavorite: !team.isFavorite,
      });
    },
    [updateTeamFavorites],
  );

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.currentTarget;
      const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_SCROLL_PADDING >= target.scrollHeight;

      if (isScrollBottom && !isLoading) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isLoading],
  );

  const itemsList = items.map((item) => (
    <MenuItem
      dense
      key={item.id}
      sx={{ borderBottom: `1px solid ${Colors.background}`, overflowY: 'auto' }}
      onClick={() => handleMenuItemClick(item.id)}
      tabIndex={0}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        width={'100%'}
        display={'grid'}
        gridTemplateColumns={'1fr auto 1fr'}
        alignItems={'center'}
        gap={2}
        sx={(theme) => ({
          [theme.breakpoints.down('xl')]: {
            gridTemplateColumns: '1fr auto auto',
          },
        })}
      >
        <Stack direction={'row'} alignItems={'center'} gap={1} overflow={'hidden'}>
          <TeamAvatar size={'xsmall'} src={item.logoUrl} />
          <Typography fontSize={fontSizes.default} overflow={'hidden'} textOverflow={'ellipsis'}>
            {item.name}
          </Typography>
        </Stack>
        <Country countryCode={item.countryCode} showCountryName={false} showTooltip />
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Tooltip title={t(item.isFavorite ? 'favorites.remove' : 'favorites.add')}>
            <IconButton size={'small'} onClick={(event) => handleFavoritesButtonClick(event, item)}>
              {item.isFavorite ? <IconStarFilled size={'small'} color={'primary'} /> : <IconStar size={'small'} />}
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </MenuItem>
  ));

  if (!itemsList.length) {
    return (
      <Typography fontSize={fontSizes.small} fontWeight={'fontWeightMedium'} sx={{ pt: 1, pb: 1, pl: 2, mt: 1 }}>
        {t('no-results')}
      </Typography>
    );
  }

  return (
    <Box maxHeight={420} overflow={'auto'} onScroll={handleScroll}>
      <MenuList sx={{ minWidth: LIST_MIN_WIDTH }}>{itemsList}</MenuList>
    </Box>
  );
};
