const routesPrefixes = {
  BACKOFFICE: '/kognia-backoffice',
  TAGGING_TOOL: '/tagging-tool',
};

export const routes = {
  PERFORMANCE_REPORT_TIMELINE: '/performance-report/:id/timeline',
  RECORDING_PLAYLIST_DETAIL: '/recordings/playlists/:playlistId',
  RECORDING_PLAYLISTS: '/recordings/:id/playlists',
  RECORDING_LIST: '/recordings',
  RECORDING_APP: '/recording-app',
  TAGGING_TOOL_TAG: `${routesPrefixes.TAGGING_TOOL}/:recordingId/tag`,
  TAGGING_TOOL: routesPrefixes.TAGGING_TOOL,
  TAGGING_TOOL_UPLOAD_STEP_ONE: `${routesPrefixes.TAGGING_TOOL}/upload`,
  TAGGING_TOOL_UPLOAD_STEP_TWO: `${routesPrefixes.TAGGING_TOOL}/upload/:recordingId`,
  TAGGING_TOOL_ALIGN_SOURCES: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align/sources`,
  TAGGING_TOOL_ALIGN_RECORDING: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align`,
  TAGGING_TOOL_ALIGN_RECORDING_WITH_SOURCE: `${routesPrefixes.TAGGING_TOOL}/:recordingId/align/:sourceRecordingId`,
  TAGGING_TOOL_RECORDING: `${routesPrefixes.TAGGING_TOOL}/:recordingId`,
  PLAYLISTS: '/playlists',
  ACCOUNT: '/account',
  KEYPADS_EDIT: '/keypads/:id',
  KEYPADS: '/keypads',
  LOGIN: '/login',
  BACKOFFICE: routesPrefixes.BACKOFFICE,
  BACKOFFICE_ANNOTATION: `${routesPrefixes.BACKOFFICE}/annotation`,
  BACKOFFICE_PLAYERS: `${routesPrefixes.BACKOFFICE}/players`,
  BACKOFFICE_TEAMS: `${routesPrefixes.BACKOFFICE}/teams`,
  PROCESS_FIXTURE: `${routesPrefixes.BACKOFFICE}/process-fixture`,
  BACKOFFICE_ENVIRONMENTS: `${routesPrefixes.BACKOFFICE}/environments`,
  BACKOFFICE_CREATE_ENVIRONMENT: `${routesPrefixes.BACKOFFICE}/create-environment`,
  BACKOFFICE_ENVIRONMENT_DETAIL: `${routesPrefixes.BACKOFFICE}/environments/:id`,
  BACKOFFICE_EDIT_ENVIRONMENT: `${routesPrefixes.BACKOFFICE}/environments/:id/edit`,
  BACKOFFICE_CREATE_ENVIRONMENT_BRANDING: `${routesPrefixes.BACKOFFICE}/environments/:id/add-branding`,
  BACKOFFICE_EDIT_ENVIRONMENT_BRANDING: `${routesPrefixes.BACKOFFICE}/environments/:id/edit-branding`,
  BACKOFFICE_ASSOCIATE_COMPETITION_WITH_CLIENTS: `${routesPrefixes.BACKOFFICE}/associate-competition-with-clients`,
  BACKOFFICE_COMPETITIONS: `${routesPrefixes.BACKOFFICE}/competitions`,
  BACKOFFICE_COACHES: `${routesPrefixes.BACKOFFICE}/coaches`,
  BACKOFFICE_FIXTURES: `${routesPrefixes.BACKOFFICE}/fixtures`,
  BACKOFFICE_EDIT_FIXTURE_FORM: `${routesPrefixes.BACKOFFICE}/edit-fixture-form/:id`,
  BACKOFFICE_FIXTURE_FORM: `${routesPrefixes.BACKOFFICE}/fixture-form/(home-team|opponent-team|fixture-info|summary)/:id?`,
  BACKOFFICE_FIXTURE_SUMMARY: `${routesPrefixes.BACKOFFICE}/fixture-summary/:id`,
  BACKOFFICE_FIXTURE_FORM_STEP_1: `${routesPrefixes.BACKOFFICE}/fixture-form/home-team/:id?`,
  BACKOFFICE_FIXTURE_FORM_STEP_2: `${routesPrefixes.BACKOFFICE}/fixture-form/opponent-team/:id?`,
  BACKOFFICE_FIXTURE_FORM_STEP_3: `${routesPrefixes.BACKOFFICE}/fixture-form/fixture-info/:id?`,
  BACKOFFICE_NEW_FIXTURE_SUMMARY: `${routesPrefixes.BACKOFFICE}/fixture-form/summary/:id`,
  BACKOFFICE_VENUES: `${routesPrefixes.BACKOFFICE}/venues`,
  BACKOFFICE_SEASON_COMPETITIONS: `${routesPrefixes.BACKOFFICE}/season-competitions`,
  BACKOFFICE_STAGES: `${routesPrefixes.BACKOFFICE}/stages`,
  BACKOFFICE_USERS: `${routesPrefixes.BACKOFFICE}/users`,
  SUBMIT_FEEDBACK:
    'https://docs.google.com/forms/d/e/1FAIpQLSdIWFqMxFlxLmRNz4IVzrTmBYJHyOEhO_Ohmssph7-A510Mwg/viewform',
  HOME_PAGE: '/',
  TEAM_PROFILE: '/team-profile/:teamId',
  PLAYER_PROFILE: '/player-profile/:playerId',
  PLAYER_PROFILE_DASHBOARD: '/player-profile-dashboard',
  DASHBOARD_PREVIEW: '/dashboard-preview',
  LOGOUT: '/logout',
  DOWNLOAD_MANAGER: '/download-manager',
  EXPLORE: '/explore',
} as const;

const routesSectionsList = {
  HOME: 'home',
  TACTICAL_ANALYSIS: 'tacticalAnalysis',
  TAGGING: 'tagging',
  PLAYLISTS: 'playlists',
  KEYPADS: 'keypads',
  ACCOUNT: 'account',
  SUPPORT: 'support',
  BACKOFFICE_ANNOTATION: 'backoffice-annotation',
  BACKOFFICE_METADATA_FIXTURES: 'backoffice-metadata-fixtures',
};

// assign route to a section to set active specific link (sidebar-menu)

export const routesSections = {
  [routes.PERFORMANCE_REPORT_TIMELINE]: routesSectionsList.TACTICAL_ANALYSIS,
  [routes.RECORDING_PLAYLIST_DETAIL]: routesSectionsList.PLAYLISTS,
  [routes.RECORDING_PLAYLISTS]: routesSectionsList.PLAYLISTS,
  [routes.RECORDING_LIST]: routesSectionsList.TACTICAL_ANALYSIS,
  [routes.TAGGING_TOOL_TAG]: routesSectionsList.TAGGING,
  [routes.TAGGING_TOOL]: routesSectionsList.TAGGING,
  [routes.PLAYLISTS]: routesSectionsList.PLAYLISTS,
  [routes.ACCOUNT]: routesSectionsList.ACCOUNT,
  [routes.KEYPADS_EDIT]: routesSectionsList.KEYPADS,
  [routes.KEYPADS]: routesSectionsList.KEYPADS,
  [routes.HOME_PAGE]: routesSectionsList.HOME,
  [routes.BACKOFFICE_ANNOTATION]: routesSectionsList.BACKOFFICE_ANNOTATION,
  [routes.BACKOFFICE_FIXTURES]: routesSectionsList.BACKOFFICE_METADATA_FIXTURES,
  [routes.BACKOFFICE]: routesSectionsList.BACKOFFICE_METADATA_FIXTURES,
};

export enum playlistPageQueryParams {
  copyPlaylist = 'copyPlaylist',
}
