import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadPlaylist } from 'api/playlist/useDownloadPlaylist';
import { PlaylistDownloadType } from 'api/playlist/useDownloadPlaylist/types/playlistDownloadType';
import { useDownloadPlaylistXml } from 'api/playlist/useDownloadPlaylistXml';
import { useDownloadRequestName } from 'entities/download-manager/use-download-request-name/useDownloadRequestName';
import styles from 'features/playlist/download-playlist-items-modal/DownloadModal.module.scss';
import { DialogNew } from 'shared/components/dialog-new';
import { Playlist } from 'shared/types';

interface Props {
  onClose: () => void;
  playlistData: Playlist;
  downloadType: PlaylistDownloadType;
}

const DOWNLOAD_TYPE = {
  SINGLE_VIDEO_FILE: 'single-video-file',
  MULTIPLE_VIDEO_FILES: 'multiple-video-files',
};

export const DownloadPlaylistItemsModal = ({ onClose, playlistData, downloadType }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [showOverlays, setShowOverlays] = React.useState(true);
  const [downloadTypeValue, setDownloadTypeValue] = React.useState(DOWNLOAD_TYPE.SINGLE_VIDEO_FILE);
  const [showTitles, setShowTitles] = React.useState(true);
  const [exportXml, setExportXml] = React.useState(false);
  const downloadPlaylist = useDownloadPlaylist();
  const downloadPlaylistXml = useDownloadPlaylistXml(
    playlistData.id,
    playlistData.playlistItems.map((item) => item.id),
  );
  const { createClipsName, createFullPlaylistName } = useDownloadRequestName();

  const handleSubmit = useCallback(() => {
    const joinSources = downloadTypeValue === DOWNLOAD_TYPE.SINGLE_VIDEO_FILE;

    const name =
      downloadType === 'playlistItems'
        ? createClipsName(playlistData.name, playlistData.playlistItems.length)
        : createFullPlaylistName(playlistData.name);

    downloadPlaylist({
      type: downloadType,
      data: playlistData,
      params: {
        name,
        joinSources,
        showOverlays,
        showTitles,
      },
    });

    if (exportXml && downloadType !== 'temporaryPlaylist') {
      downloadPlaylistXml(name);
    }

    onClose();
  }, [
    downloadTypeValue,
    downloadType,
    createClipsName,
    playlistData,
    createFullPlaylistName,
    downloadPlaylist,
    showOverlays,
    showTitles,
    exportXml,
    onClose,
    downloadPlaylistXml,
  ]);

  const handleDownloadTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadTypeValue((event.target as HTMLInputElement).value);
  }, []);

  const handleShowOverlaysChange = useCallback((event: SyntheticEvent<Element, Event>) => {
    const showOverlays = (event.target as HTMLInputElement).checked;
    setShowOverlays(showOverlays);
  }, []);

  const handleTitleChange = useCallback((event: SyntheticEvent<Element, Event>) => {
    const showTitle = (event.target as HTMLInputElement).checked;
    setShowTitles(showTitle);
  }, []);

  const handleExportXml = useCallback((event: SyntheticEvent<Element, Event>) => {
    const exportXmlChecked = (event.target as HTMLInputElement).checked;
    setExportXml(exportXmlChecked);
  }, []);

  const isSinglePlaylistItemDownload = playlistData.playlistItems.length === 1;

  return (
    <DialogNew
      open={true}
      onClose={onClose}
      title={t('playlist-detail:download-modal.title')}
      icon={<IconDownload size='small' />}
      maxWidth={'sm'}
      fullWidth
      onCancel={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={t('common:actions.download')}
    >
      <Box>
        <Box marginBottom={5}>
          {!isSinglePlaylistItemDownload ? (
            <Box marginBottom={4}>
              <FormControl>
                <div className={styles.title}>{t('playlist-detail:download-modal.choose-download-type')}</div>
                <RadioGroup
                  aria-labelledby='download-type'
                  name='download-type'
                  value={downloadTypeValue}
                  onChange={handleDownloadTypeChange}
                >
                  <FormControlLabel
                    value={DOWNLOAD_TYPE.SINGLE_VIDEO_FILE}
                    control={<Radio color='primary' />}
                    label={
                      <div className={styles.modalRadioLabel}>
                        {t('playlist-detail:download-modal.single-video-file')}
                      </div>
                    }
                  />
                  <FormControlLabel
                    value={DOWNLOAD_TYPE.MULTIPLE_VIDEO_FILES}
                    control={<Radio color='primary' />}
                    label={
                      <div className={styles.modalRadioLabel}>
                        {t('playlist-detail:download-modal.multiple-video-files')}
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : null}
        </Box>

        <Box>
          <div className={styles.title}>{t('playlist-detail:download-modal.choose-preferences')}</div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={showOverlays} />}
              label={
                <Typography fontSize={fontSizes.default}>
                  {t('playlist-detail:download-modal.show-tactical-drawings')}
                </Typography>
              }
              onChange={handleShowOverlaysChange}
            />
            <FormControlLabel
              control={<Checkbox checked={showTitles} />}
              label={
                <Typography fontSize={fontSizes.default}>{t('playlist-detail:download-modal.show-titles')}</Typography>
              }
              onChange={handleTitleChange}
            />
            {downloadType !== 'temporaryPlaylist' ? (
              <FormControlLabel
                control={<Checkbox checked={exportXml} />}
                label={
                  <Typography fontSize={fontSizes.default}>{t('playlist-detail:download-modal.export-xml')}</Typography>
                }
                onChange={handleExportXml}
              />
            ) : null}
          </FormGroup>
        </Box>
      </Box>
    </DialogNew>
  );
};
