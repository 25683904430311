import { Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { optimisticFavoritesUpdateTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { useUpdateTeamFavorites } from 'entities/dashboard/api/useUpdateTeamFavorites';
import { Country } from 'features/dashboard/team-profile-header-feature/ui/Country';
import { FavoriteStarButton } from 'shared/components/FavoriteStarButton';
import { ProfileInfoWrapper } from 'shared/components/ProfileInfoWrapper';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { TeamWithPlayers } from 'shared/types/team/team';

export const TeamInfo = ({ team }: { team: TeamWithPlayers }) => {
  const { t } = useTranslation(['common']);
  const favoritesFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_FAVORITES);
  const { updateTeamFavorites } = useUpdateTeamFavorites();

  return (
    <ProfileInfoWrapper>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <TeamAvatar size={'xlarge'} src={team.logoUrl} />
        <Stack gap={1}>
          <Typography fontSize={fontSizes.medium} fontWeight={(theme) => theme.typography.fontWeightBold}>
            {team.name}
          </Typography>
          <Country countryCode={team.countryCode} />
          {team.teamDefaults ? <Typography variant={'body2'}>{team.teamDefaults.competition.name}</Typography> : null}
        </Stack>

        {favoritesFeatureFlag ? (
          <FavoriteStarButton
            isFavorite={team.isFavorite}
            onClick={() =>
              updateTeamFavorites({
                teamId: team.id,
                isFavorite: !team.isFavorite,
                onMutate: optimisticFavoritesUpdateTeamWithDefaults,
              })
            }
            title={t(team.isFavorite ? 'favorites.remove' : 'favorites.add')}
          />
        ) : null}
      </Stack>
    </ProfileInfoWrapper>
  );
};
