import { zodResolver } from '@hookform/resolvers/zod';
import { IconUser } from 'kognia-ui/icons/IconUser';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateCompetition } from 'pages/backoffice/api/competitions/use-create-competition';
import { useUpdateCompetition } from 'pages/backoffice/api/competitions/use-update-competition';
import { CompetitionsForm } from 'pages/backoffice/pages/competitions/components/competition-form';
import { competitionFormSchema } from 'pages/backoffice/pages/competitions/components/competition-form/form';
import { CompetitionFormSchema } from 'pages/backoffice/pages/competitions/components/competition-form/types';
import { DialogNew } from 'shared/components/dialog-new';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: CompetitionFormSchema;
}

const competitionsFormId = 'competitions-form';

export const CompetitionModal = ({ open, onClose, defaultValues }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CompetitionFormSchema>({
    resolver: zodResolver(competitionFormSchema),
    defaultValues,
  });

  const { create } = useCreateCompetition();
  const { update } = useUpdateCompetition();

  const handleCreate = useCallback(
    (data: CompetitionFormSchema) => {
      create({
        data,
        onSuccess: () => {
          onClose();
          reset();
        },
      });
    },
    [create, onClose, reset],
  );

  const handleUpdate = useCallback(
    (data: CompetitionFormSchema) => {
      if (!data?.id) return;
      const { id, ...rest } = data;

      update({
        data: rest,
        id,
        onSuccess: onClose,
      });
    },
    [onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: CompetitionFormSchema) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit competition' : 'New competition'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={competitionsFormId}
    >
      <form id={competitionsFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <CompetitionsForm errors={errors} register={register} />
      </form>
    </DialogNew>
  );
};
