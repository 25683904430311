import { Box, Stack } from '@mui/material';
import { Breakpoint, Colors, generateContainerQueries } from 'kognia-ui';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { KogniaLogo } from 'pages/explore/ui/KogniaLogo';
import bgImage from 'shared/assets/bg-radar.webp';
import Container from 'shared/components/container';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { CompetitionsWidget } from 'widgets/competitions/Competitions.widget';
import { DashboardSearchWidget } from 'widgets/dashboard/dashboard-search/DashboardSearch.widget';
import { FavoritesCardWidget } from 'widgets/dashboard/favorites-card/FavoritesCard.widget';

const MIN_CARD_WIDTH = '320px';
const MAX_CARD_WIDTH = 488;

export const ExplorePage = () => {
  const favoritesFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_FAVORITES);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: Colors.background,
        backgroundImage: `url("${bgImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `center top`,
        backgroundSize: 'cover',
        containerType: 'inline-size',
      }}
    >
      <Container>
        <Stack gap={5} alignItems='center' pt={9}>
          <KogniaLogo />
          <DashboardSearchWidget width={MAX_CARD_WIDTH} />

          <Box
            display='grid'
            justifyContent='center'
            gap={3}
            width='100%'
            sx={{
              ...generateContainerQueries({
                [Breakpoint.LG]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, 1.4fr) minmax(${MIN_CARD_WIDTH}, 1fr)`,
                },
                [Breakpoint.MD]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, 1fr) minmax(${MIN_CARD_WIDTH}, 1fr)`,
                },
                [Breakpoint.XS]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, ${MAX_CARD_WIDTH}px)`,
                },
              }),
            }}
          >
            {favoritesFeatureFlag && <FavoritesCardWidget />}

            <CompetitionsWidget />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
